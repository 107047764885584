import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Register from "@/views/register/Register.vue";
import Register_1 from "@/views/register/Register_1.vue";
import Register_2 from "@/views/register/Register_2.vue";
import Profile from "@/views/profile/Profile.vue";
import Edit from "@/views/edit/Edit.vue";
import Edit_1 from "@/views/edit/Edit_1.vue";

import Electrict from "@/views/detect/electrict.vue";
import Paper from "@/views/detect/paper.vue";
import Oil from "@/views/detect/oil.vue";

import Tree from "@/views/detect/tree.vue";
import Methane from "@/views/detect/methane.vue";
import Garbage from "@/views/detect/garbage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/step1",
    name: "Register_1",
    component: Register_1,
  },
  {
    path: "/register/step2",
    name: "Register_2",
    component: Register_2,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/edit",
    name: "Edit",
    component: Edit,
  },

  {
    path: "/edit_1",
    name: "Edit_1",
    component: Edit_1,
  },

  {
    path: "/detect/electrict",
    name: "Electrict",
    component: Electrict,
  },

  {
    path: "/detect/paper",
    name: "Paper",
    component: Paper,
  },

  {
    path: "/detect/oil",
    name: "Oil",
    component: Oil,
  },

  {
    path: "/detect/tree",
    name: "Tree",
    component: Tree,
  },

  {
    path: "/detect/methane",
    name: "Methane",
    component: Methane,
  },

  {
    path: "/detect/garbage",
    name: "Garbage",
    component: Garbage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
