import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("register", data);
  };

  const UpdateUser = async (data) => {
    return await ApiService.put("edit_user/" + data.id_line, data);
  };


  const store_electrict = async (data) => {
    return await ApiService.post("store_electrict", data);
  };

  const store_oil = async (data) => {
    return await ApiService.post("store_oil", data);
  };

  const store_paper = async (data) => {
    return await ApiService.post("store_paper", data);
  };


  const store_tree = async (data) => {
    return await ApiService.post("store_tree", data);
  };

  const store_methane = async (data) => {
    return await ApiService.post("store_methane", data);
  };

  const store_garbage = async (data) => {
    return await ApiService.post("store_garbage", data);
  };


  return {
    Register,
    UpdateUser,
    store_electrict,
    store_paper,
    store_oil,
    store_tree,
    store_methane,
    store_garbage
  };
}
