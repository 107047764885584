<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png"
            style="width: 100px; border-radius: 50%;   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;" />
          <div class="t1" style="margin: 5px;font-size: 20px; font-weight: 600; color: #e8bc66;">เก็บข้อมูล<br>การเผามีเทนได้ไฟฟ้า
          </div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form" class="form"
          novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">รอบเดือน</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row">

                    <div class="col-6">
                      <Field as="select" name="month" class="form-control form-control-solid"
                        v-model="data_register.month">
                        <option value="" disabled selected>เดือน</option>
                        <option v-for="n in monthNamesThai" :key="n.id" :value="n.name">
                          {{ n.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="month" />
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <Field as="select" name="year" class="form-control form-control-solid" v-model="data_register.year">
                        <option value="" disabled selected>ปี (พ.ศ.)</option>
                        <option v-for="(n, index) in setRange()" :key="index" :value="n">
                          {{ n }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="year" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>




              <div class="row mb-6">
                <!--begin::Label-->
                <div class="col-6">
                  <label class="col-lg-4 col-form-label fw-bold fs-6">บ่อที่ 1</label>
                  <div class="col-lg-12 fv-row">

                    <Field type="number" name="mwh_1" class="form-control  form-control-solid" placeholder="จำนวน Mw"
                      v-model="data_register.mwh_1" />

                  </div>
                </div>

                <div class="col-6">
                  <label class="col-lg-4 col-form-label fw-bold fs-6">บ่อที่ 2</label>
                  <div class="col-lg-12 fv-row">

                    <Field type="number" name="mwh_2" class="form-control  form-control-solid" placeholder="จำนวน Mw"
                      v-model="data_register.mwh_2" />

                  </div>
                </div>

                <!--end::Col-->
              </div>




              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">แนบไฟล์ (pdf,xls,xlsx,doc,docx,pptx )</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="fileUrl" v-model="fileUrl" />

                  <input type="file" id="getFilefile" multiple="multiple" @input="pickFilefile" style="display: none" />
                  <button type="button" class="btn addimage" @click="selectFile()">
                    <img style="width: 50px" src="@/assets/icon/folder.png" /><br />
                    แนบไฟล์
                  </button>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <div v-if="fileUrl.length !== 0" class="imagePreviewWrapper" style="padding: 15px 0px">
                  <div class="row">
                    <div style="position: relative" class="col-3" v-for="(item, index) in fileUrl" :key="index">
                      <div class="xx" @click="deleteFile(index)">
                        <span>x</span>
                      </div>
                      <img class="images" :src="checkIcon(item.type)" />
                      <h6 class="text-black text-truncate">{{ item.name }} </h6>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>

          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  บันทึกข้อมูล
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "@/assets/icon/pdf.png";
import excel from "@/assets/icon/excel.png";
import powerpoint from "@/assets/icon/powerpoint.png";
import word from "@/assets/icon/word.png";
import folder from "@/assets/icon/folder.png";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
import moment from "moment";
export default defineComponent({
  name: "Paper",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const fileUrl = ref([]);
    const imageUrl = ref([]);
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { store_methane } = useRegister();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;
    let place_status = ref(false);
    const register = Yup.object().shape({
      month: Yup.string().required("กรุณาเลือก เดือน"),
      mwh_1: Yup.string(),
      mwh_2: Yup.string(),
      year: Yup.string().required("กรุณาเลือก ปี"),
      fileUrl: Yup.array()
    });

    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);


    const setRange = () => {
      let th_year = moment().year() + 543;
      let value = [];
      let i;
      for (i = th_year; i >= th_year - 100; i--) {
        value.push(i);
      }
      return value;
    };



    const selectFile = () => {
      document.getElementById("getFilefile").click();
    };

    const pickFilefile = async (e) => {
      const files = e.target.files;
      //console.log(files);
      for (var i = 0; i < files.length; i++) {
        const name = files[i].name;
        const lastDot = name.lastIndexOf(".");
        const ext = name.substring(lastDot + 1);
        if (
          ext === "pdf" ||
          ext === "xls" ||
          ext === "xlsx" ||
          ext === "doc" ||
          ext === "docx" ||
          ext === "pptx "
        ) {
          fileUrl.value.push({
            name: name,
            data: await image_to_base64(files[i]),
            type: ext,
          });
        } else {
          SconfirmNc("ประเภทไฟล์ ไม่ถูกต้อง", "error", "ลองอีกครั้ง !");
        }
      }
    };



    const back = () => {
      router.push({ name: "Register_1" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {


      if (values.mwh_1 || values.mwh_2) {

        values.id_line = store.getters.getAccessToken.accesstoken;
        store.dispatch(SET_REGISTER, values);


        console.log(store.getters.getRegister);
        Sconfirm("ยืนยัน การบันทึกข้อมูล", "question").then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;

            await store_methane(store.getters.getRegister)
              .then((result) => {
                isLoading.value = false;
                if (result.data.status === "success") {
                  SconfirmNc("การบันทึกข้อมูลสำเร็จ", "success", "ตกลง").then(() => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  });
                } else {
                  SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                    () => {
                      liff.ready.then(() => {
                        liff.closeWindow();
                      });
                    }
                  );
                }
              })
              .catch((error) => {
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.id_line) {
                    SToast("error", `${errors.id_line[0]}`);
                  } else {
                    SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                  }
                }
              });
          }
        });
      } else {
        SconfirmNc("กรุณากรอกข้อมูล จำนวนไฟฟ้าที่ผลิต อย่างน้อย 1 ค่า", "error", "ตกลง");
      }
    };

    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const deleteFile = (val) => {
      fileUrl.value.splice(val, 1);
    };

    function checkIcon(val) {
      if (val === "pdf") {
        return pdf;
      } else if (val === "xls" || val === "xlsx") {
        return excel;
      } else if (val === "pptx") {
        return powerpoint;
      } else if (val === "doc" || val === "docx") {
        return word;
      } else {
        return folder;
      }
    }

    const pickFile = async (e) => {
      const files = e.target.files;


      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };


    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      monthNamesThai,
      setRange,
      place_status,
      selectImage,
      pickFile,
      deleteImage,
      imageUrl,
      pdf,
      excel,
      powerpoint,
      word,
      folder,
      pickFilefile,
      selectFile,
      deleteFile,
      checkIcon,
      fileUrl
    };
  },
});
</script>

<style scoped>
.icon {

  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
  height: 80px !important;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.images {
  width: 100%;
}

.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}

.addimage {
  width: 100%;
  border: 1px solid var(--color) !important;
}
</style>
